import React, { FC, ReactNode, useRef, useState } from 'react';
import { Text, Button, ButtonPriority, ButtonSize, ThreeDotsLoader, Divider } from 'wix-ui-tpa/cssVars';
import { StartType } from '@wix/ambassador-pricing-plans-v3-plan/types';
import type { FormValues, FormViewerHandle } from '@wix/form-viewer/widget';
import { useStyles } from '@wix/tpa-settings/react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import loadable from '@wix/yoshi-flow-editor/loadable';
import { withClientSideRenderMark } from '../../../contexts/ClientSideRenderMark';
import componentStyles from '../stylesParams';
import { PlanCustomizationProps } from '../types';
import { AdditionalDetailsLoader } from './AdditionalDetails/AdditionalDetailsLoader';
import { EditorPreviewNotification } from './EditorPreviewNotification/EditorPreviewNotification';
import { st, classes } from './PlanCustomizationWidget.st.css';
import { PlanPreview } from './PlanPreview/PlanPreview';
import { PlanStartDatePickerLoader } from './PlanStartDatePicker/PlanStartDatePickerLoader';

const LazyPlanStartDatePicker = loadable(() => import('./PlanStartDatePicker/PlanStartDatePicker'), {
  fallback: <PlanStartDatePickerLoader />,
});
const LazyAdditionalDetails = loadable(() => import('./AdditionalDetails/AdditionalDetails'), {
  fallback: <AdditionalDetailsLoader />,
});

const ProductPageWidget: React.FC<PlanCustomizationProps> = (props) => {
  const styles = useStyles();
  const { t } = useTranslation();

  if (props.status === 'PAGE_LOADING') {
    // TODO: Add loader
    return null;
  }

  const hasShadow = styles.get(componentStyles.planFormEnableShadow);
  const hasBorder = !!styles.get(componentStyles.planFormBorderWidth);

  return (
    <>
      <EditorPreviewNotification />
      <div className={classes.root}>
        {styles.get(componentStyles.showPlanCard) && (
          <aside className={classes.previewContainer}>
            <PlanPreview plan={props.plan} />
          </aside>
        )}
        <main
          className={st(classes.contentContainer, {
            'with-padding': hasShadow || hasBorder,
            'with-shadow': hasShadow,
          })}
        >
          {styles.get(componentStyles.showPageTitle) && (
            <header className={classes.header}>
              <Text
                className={classes.pageTitle}
                tagName={
                  styles.get(componentStyles.pageTitleFont).htmlTag ??
                  styles.getDefaultValue(componentStyles.pageTitleFont).htmlTag
                }
              >
                {t('plan-customization.widget.form-title')}
              </Text>
              <Divider className={classes.headerDivider} />
            </header>
          )}
          <ProductCustomizationForm {...props} />
        </main>
      </div>
    </>
  );
};

const ProductCustomizationForm: FC<PlanCustomizationProps> = ({
  plan,
  status,
  formControllerStatus,
  proceedToPayment,
  minStartDate,
  maxStartDate,
}) => {
  const formViewerRef = useRef<FormViewerHandle>(null);
  const [formValues, setFormValues] = useState<FormValues>({});
  const [customStartDate, setCustomStartDate] = useState<Date>(new Date(minStartDate ?? Date.now()));
  const { t } = useTranslation();

  const isCheckoutLoading = status === 'CHECKOUT_LOADING';
  const allowFutureStartDate = plan.pricingVariants?.[0].billingTerms?.startType === StartType.CUSTOM;
  const hasConnectedForm = !!plan.formId;

  const onContinueClick = async () => {
    // TODO: What to do if (hasConnectedForm && !formViewerRef.current) ?
    if (hasConnectedForm && formViewerRef.current) {
      const isValid = await formViewerRef.current?.validate();
      if (!isValid) {
        return;
      }
    }

    proceedToPayment({
      startDate: allowFutureStartDate ? customStartDate.toString() : null,
      formValues: hasConnectedForm ? formValues : null,
    });
  };

  return (
    <div>
      {allowFutureStartDate && (
        <LazyPlanStartDatePicker
          className={classes.datePicker}
          value={customStartDate}
          onChange={(date) => setCustomStartDate(date)}
          min={minStartDate}
          max={maxStartDate}
        />
      )}
      {plan.formId && (
        <LazyAdditionalDetails
          plan={plan}
          formValues={formValues}
          setFormValues={setFormValues}
          ref={formViewerRef}
          formControllerStatus={formControllerStatus}
        />
      )}
      <ContinueButton loading={isCheckoutLoading} onClick={onContinueClick}>
        {t('plan-customization.widget.button-text')}
      </ContinueButton>
      <Text tagName="p" className={classes.disclaimer}>
        {t('plan-customization.widget.disclaimer')}
      </Text>
    </div>
  );
};

const ContinueButton: FC<{ loading: boolean; children: ReactNode; onClick: () => void }> = ({
  loading,
  onClick,
  children,
}) => (
  <Button
    size={ButtonSize.large}
    className={st(classes.ctaButton, { loading })}
    fullWidth
    upgrade
    priority={ButtonPriority.primary}
    onClick={onClick}
    disabled={loading}
  >
    <span className={classes.label}>{children}</span>
    {loading && (
      <div className={classes.loaderContainer}>
        <ThreeDotsLoader className={classes.loader} />
      </div>
    )}
  </Button>
);

export default withClientSideRenderMark(ProductPageWidget);
